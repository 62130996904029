import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFound = () => {
	return(
		<Layout>
			<Seo title="NotFound" />
            Not Found
		</Layout>
	)
}

export default NotFound